<template>
  <div class="container user-login">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-5">
        <div class="card c-card">
          <div class="card-header">
            <FormHeader />
          </div>
          <div class="card-body login-form center">
            <h5 class="card-title login-form-title mt-4">Garson Uygulaması</h5>
            <p class="login-form-desc">Sinerpos Servis Elemanı Sipariş Sistemi.</p>
            <div class="form-group top">
              <input
                type="email"
                class="form-control login-input"
                v-model="email"
                placeholder="E-posta Adresiniz"
                @blur="validateEmail"
              />
              <div v-if="emailError" class="alert">{{ emailError }}</div>
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control login-input"
                v-model="password"
                placeholder="Şifreniz"
                @blur="validatePassword"
              />
              <div v-if="passwordError" class="alert">{{ passwordError }}</div>
            </div>
            <div v-if="emailorPasswordError" class="alert">
              {{ emailorPasswordError }}
            </div>
            <div class="form-group">
              <button class="btn btn-primaryx login-form-loginBtn" @click="login">
                Giriş Yap
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import FormHeader from "../../components/FormHeader/FormHeader.vue";
import router from "../../router/index";
import axios from "axios";
import { setLoading } from "../../store/app.js";
import { ref } from "vue";

const email = ref("");
const password = ref("");
const emailError = ref("");
const passwordError = ref("");
const emailorPasswordError = ref("");

const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email.value) {
    emailError.value = "E-posta adresi gereklidir.";
  } else if (!emailPattern.test(email.value)) {
    emailError.value = "Geçerli bir e-posta adresi girin.";
  } else {
    emailError.value = "";
  }
};

const validatePassword = () => {
  if (!password.value) {
    passwordError.value = "Şifre gereklidir.";
  } else {
    passwordError.value = "";
  }
};

const login = () => {
  validateEmail();
  validatePassword();

  if (!emailError.value && !passwordError.value) {
    setLoading(true);

    axios({
      method: "POST",
      url: "v2/login",
      data: {
        email: email.value,
        password: password.value,
      },
    })
      .then(async (response) => {
        console.log("response", response);
        if (response.data.success === true) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("domain", response.data.user.tenant.domain);
          localStorage.setItem("userData", JSON.stringify(response.data.user));
          await router.push({ name: "PinLogin" });
        }
        setLoading(false);
      })
      .catch(() => {
        emailorPasswordError.value = "E-posta adresi veya şifre hatalı.";
        setLoading(false);
      });
  }
};
</script>
<style src="./UserLogin.scss" lang="scss" scoped></style>
