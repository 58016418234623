<template>
  <button ref="htmlRefHook"
    :class="['card table-item', { 'move': appStore.willMoveTableId == props.table.id }, statusOfCardClasses]" style="user-select: none;"
    @click="handleTableSelected">
    <div class="status-bar">
      <div class="block bold justify-content-center table-name">{{ props.table.name }}</div>
    </div>
    <div class="card-inner">
      <div v-if="props.table.status === 0" class="block inner-text">Boş</div>
      <div v-else-if="props.table.status === 2" class="block inner-text reserved">
        <i class="bi bi-clock"></i> Rezerve
      </div>
      <div v-else-if="props.table.status === 1 || props.table.status === 3" class="block inner-text">
        {{ formatPrice(props.table.amount) }}
      </div>
    </div>
    <div v-if="props.table.status === 1 || props.table.status === 2 || props.table.status === 3" class="w-100">
      <div class="block small justify-content-between">
        <!-- <div class="name">{{ props.table.reservation }}</div> -->
        <div></div>
        <div class="time">{{ calculateTime(props.table.updated_at) }}</div>
      </div>
    </div>
  </button>
</template>

<script setup>
import { defineProps, computed, ref, onMounted } from "vue";
import formatPrice from "../../utils/formatPrice.ts";
import calculateTime from "../../utils/calculateTime.ts";
import router from "../../router";
import { changeTable, tableDetailStore } from "../../store/table-detail.js";
import { appStore, setMoveTableId } from "@/store/app.js";
import { onLongPress } from '@vueuse/core'

const htmlRefHook = ref();

const props = defineProps({
  table: {
    type: Object,
    required: true,
  },
});


const onLongPressCallbackHook = () => {
  if (props.table.status !== 0 && props.table.status !== 2 && props.table.status !== 3) {
    if (appStore.willMoveTableId == props.table.id) {
      setMoveTableId(null);
    } else {
      setMoveTableId(props.table.id);
    }
  }
}

onLongPress(
  htmlRefHook,
  onLongPressCallbackHook,
  {
    modifiers: {
      prevent: true,
      stop: true
    }
  }
)



const moveTable = async () => {

  await changeTable({ willChangeTableId: appStore.willMoveTableId, toTableId: props.table.id });
  close();
};


const statusOfCardClasses = computed(() => {
  return {
    empty: props.table.status === 0,
    full: props.table.status === 1,
    reserved: props.table.status === 2,
    active: props.table.status === 3,
  };
});

const handleTableSelected = () => {
  if (appStore.willMoveTableId != null && props.table.status === 0) {
    moveTable();
  } else {
    router.push(`/tables/${props.table.id}`);
    tableDetailStore.table.id = props.table.id;
    tableDetailStore.table.area_id = props.table.area_id;
  }
};

</script>
<style src="./TableItem.scss" lang="scss"></style>
