const calculateTime = (timestamp) => {
  if (!timestamp) {
    return ''; // Handle null or undefined case
  }

  const date = new Date(timestamp);
  const hour = ('0' + date.getHours()).slice(-2);
  const minute = ('0' + date.getMinutes()).slice(-2);
  return `${hour}:${minute}`;
};

export default calculateTime;
