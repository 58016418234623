<template>
    <button class="card menu-item no-selectable" @click="addToCart" v-touch:hold="openModal" >
      <div class="card-inner">
        <img @contextmenu.prevent="() => false" class="no-selectable" :src="nevaCDN + props.product.image" :alt="props.product.name" />
        <div class="w-100 center mt-1 nevaposs">
          <p @contextmenu.prevent="() => false" class="no-selectable product-name">{{ props.product.name }}</p>
          <p @contextmenu.prevent="() => false" class="title-text w-100 center no-selectable" style="height: 20px">
            {{ formatPrice(props.product.price) }}
          </p>
        </div>
      </div>
    </button>
    <MenuItemOptionsModal v-if="modalVisible" :features="props.product.features" @close="closeModal" />
</template>

<script setup>
import { ref, defineProps, watch } from "vue";
import MenuItemOptionsModal from "./MenuItemOptionsModal/MenuItemOptionsModal.vue";
import formatPrice from "../../utils/formatPrice.ts";
import { tableDetailStore, addToCartProduct, setAddedProductFeatures } from "../../store/table-detail";
import { toast } from "vue3-toastify";

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const nevaCDN = 'https://sinerpos-cdn.fra1.cdn.digitaloceanspaces.com/upload/';

const openModal = (e) => {
  e.preventDefault()
  if (props.product.hasOwnProperty("features") && props.product.features.length) {
      setAddedProductFeatures(props.product.features);
  }
  tableDetailStore.selectedProductOnFeature = props.product;
  openAddOrderModal()
}

const addToCart = (e) => {
  e.preventDefault()
  addToCartProduct({
    ...props.product,
    quantity: 1,
    updated_at: new Date().toString(),
    portion: 1,
  });
};

const modalVisible = ref(false);

const openAddOrderModal = () => {
  modalVisible.value = true;
};

const closeModal = () => {
  modalVisible.value = false;
};

watch(() => modalVisible.value, (newVal) => {
  if(newVal) {
    document.body.style.overflow="hidden"
  } else {
    document.body.style.overflow="auto"
  }
})
</script>

<style src="./MenuItem.scss" lang="scss">
</style>

<style lang="scss" scoped>
.no-selectable {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
}

.no-selectable * {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
}
.product-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
