<template>
  <footer class="app-footer footer">
    <div class="items">
      <div class="item" v-for="(item, index) in props.footerItems" :key="index" style="display: flex;
      align-items: center;
      justify-content: center;">
        <button :class="['btn footer-inner', {
          'active': type == item.type
        }]" @click="handleClick(item.type)">
          <i :class="item.icon" :style="`${item.type === 'note' ? 'font-size: 16px;' :''}`" aria-hidden="true"></i>
          <div class="text">{{ item.text }}</div>
        </button>
      </div>
    </div>
  </footer>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  footerItems: {
    type: Array,
    required: true,
  }
});

const emitClick = defineEmits(['item-clicked']);

const handleClick = (type) => {
  emitClick('item-clicked', type);
};

</script>
<style src="./AppFooter.scss" lang="scss"></style>
