<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 logo">
        <img src="/img/nevapos-logo.png">
      </div>
    </div>
  </div>
</template>
<style src="./FormHeader.scss" lang="scss"></style>
