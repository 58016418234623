<template>
  <div class="modal">
    <div class="modal-content">
      <div><span class="close" @click="close">&times;</span></div>
      <div class="d-flex flex-row justify-content-start align-items-center gap-3">
        <div class="w-100">
          <div class="features">
            <div class="d-flex align-items-center flex-wrap gap-3 w-100">
              <div>
                <input type="radio" name="portion_05" @click="portion = portion == '0.5' ? '1' : '0.5'" value="0.5"
                  class="btn-check" id="portion_05" autocomplete="off">
                <label class="btn"
                  :class="{ 'active': portion == '0.5', 'disabled': !parseFloat(tableDetailStore.selectedProductOnFeature.small_price) }"
                  for="portion_05">
                  <div class="d-flex flex-column gap-1 justify-content-center align-items-center">
                    <span class="fw-medium text-title">Az Porsiyon</span>
                    <span class="small" style="color: #ed144a;">₺{{
                      parseFloat(tableDetailStore.selectedProductOnFeature.small_price) }}</span>
                  </div>
                </label>
              </div>
              <div>
                <input type="radio" name="portion_15" @click="portion = portion == '1.5' ? '1' : '1.5'" value="1.5"
                  class="btn-check" id="portion_15" autocomplete="off">
                <label class="btn" :class="{ 'active': portion == '1.5' }" for="portion_15">
                  <div class="d-flex flex-column gap-1 justify-content-center align-items-center">
                    <span class="fw-medium text-title">1.5 Porsiyon</span>
                    <span class="small" style="color: #ed144a;">x1.5</span>
                  </div>
                </label>
              </div>
              <div>
                <input type="radio" name="portion_2" @click="portion = '2'" value="2" class="btn-check" id="portion_2"
                  autocomplete="off">
                <label @click="portion = '2'" class="btn" :class="{ 'active': portion == '2' }" for="portion_2">
                  <div class="d-flex flex-column gap-1 justify-content-center align-items-center">
                    <span class="fw-medium text-title">Duble Porsiyon</span>
                    <span class="small" style="color: #ed144a;">x2</span>
                  </div>
                </label>
              </div>
              <div>
                <input type="radio" name="portion_personal" @click="personal = personal ? 0 : 1" value="personal"
                  class="btn-check" id="portion_personal" autocomplete="off">
                <label class="btn" :class="{ 'active': personal }" for="portion_personal">
                  <div class="d-flex flex-column gap-1 justify-content-center align-items-center">
                    <span class="fw-medium text-title">Personel</span>
                    <span class="small" style="color: #ed144a;">₺{{
                      parseFloat(tableDetailStore.selectedProductOnFeature.personal_price) }}</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 mt-3 mb-3" v-for="(feature, i) in tableDetailStore.addedProductFeatures" :key="i">

        <div class="features" v-if="feature.type == 1">
          <div class="d-flex align-items-center flex-wrap gap-3 w-100">
            <div v-for="option in feature.options">
              <input type="radio" :name="'feature_' + feature.id" v-model="feature.selected" :value="option.id"
                @click="feature.selected = feature.selected == option.id ? null : option.id" class="btn-check"
                :id="'option_' + option.id" autocomplete="off">
              <label class="btn" :class="{ 'active': feature.selected == option.id }" :for="'option_' + option.id">
                <div class="d-flex flex-column gap-1 justify-content-center align-items-center">
                  <span class="fw-medium text-title">{{ option.name }}</span>
                  <span class="small" style="color: #ed144a;">₺{{ option.price }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>

        <div class="features" v-if="feature.type == 2">
          <div class="feature-name mb-2 small">{{ feature.name }}</div>
          <div class="d-flex align-items-center flex-wrap gap-3 w-100">
            <div v-for="option in feature.options">
              <input type="checkbox" :name="'feature_' + feature.id" v-model="option.selected" :value="option.id"
                class="btn-check" :id="'option_' + option.id" autocomplete="off">
              <label class="btn" :class="{ 'active': option.selected }" :for="'option_' + option.id">
                <div class="d-flex flex-column gap-1 justify-content-center align-items-center">
                  <span class="fw-medium text-title">{{ option.name }}</span>
                  <span class="small" style="color: #ed144a;">₺{{ option.price }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>

      </div>
      <div class="d-flex justify-content-center">
        <ItemCounter @change="quantity = $event" />
      </div>
      <div class="form-group mt-4">
        <button class="btn btn-primary btn-add" @click="onFeatureSelect">Ekle</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import ItemCounter from '../../Counter/ItemCounter.vue';
import { defineProps, defineEmits, ref } from 'vue';
import { tableDetailStore, addToCartProduct } from "../../../store/table-detail";

const props = defineProps({
  features: () => { }
});

const portion = ref("1");
const quantity = ref(1)
const personal = ref(0);
const emitClose = defineEmits(['close']);

const close = () => {
  emitClose('close');
};
const getPrice = (product) => {
  switch (product.portion) {
    case "0.5":
      return Number(product.small_price);
    case "1":
      return Number(product.price);
    case "1.5":
      return Number(product.price) * 1.5;
    case "2":
      return Number(product.price) * 2;
  }
}
const onFeatureSelect = () => {
  let product = tableDetailStore.selectedProductOnFeature

  let sendProduct = {
    ...product,
    updated_at: new Date().toString(),
    portion: portion.value,
    quantity: quantity.value ?? 1,
    personal: personal.value,
    price: getPrice({...product, portion: portion.value})
  }


  let addedFeatures = tableDetailStore.addedProductFeatures
  if (addedFeatures && addedFeatures.length > 0) {
    addedFeatures = addedFeatures.filter(feature => {
      if (feature.type == 1) {
        let selected = feature.selected
        if (selected) feature.selected = [feature.selected]
        return selected
      } else if (feature.type == 2) {
        let selectedOptionIds = feature.options.filter(option => {
          let selected = option.selected
          delete option.selected
          return selected
        }).map(option => option.id)
        feature.selected = selectedOptionIds
        return selectedOptionIds.length > 0
      }
    })

    if (addedFeatures.length > 0) {
      sendProduct.featureList = JSON.parse(JSON.stringify(addedFeatures))
    }
  }

  quantity.value = 1


  addToCartProduct(sendProduct);
  close()
}

</script>

<style lang="scss" scoped>
@import "./MenuItemOptionsModal.scss";

.btn:not(.btn-add) {
  border: 1px solid #ccc !important;
}

@media (max-width: 768px) {
  .text-title {
    font-size: 14px !important;
  }
}

.btn:hover {
  border: 1px solid #ed144a !important;
}

.btn.active {
  border: 1px solid #ed144a !important;
  background-color: #ed144a !important;
  color: #fff !important;

  span {
    color: #fff !important;
  }
}
</style>
