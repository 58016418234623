<template>
  <div class="pin-login">
    <div class="card c-card pin">
      <div class="card-header">
        <FormHeader />
      </div>
      <div class="card-body login-form center position-relative">
        <img src="/img/kilit.png" width="130px" height="130px" class="mb-4" />
        <div class="value-box">
          <div class="value">
            <input class="pass-input" disabled type="text" name="" :value="replaceWithAsterisk(passwordText)" />
          </div>
          <button class="login-btn" @click="loginUser">
            <ion-icon name="arrow-forward-outline" style="font-size: 22px" />
          </button>
        </div>

        <div class="buttons">
          <button @click="passwordText += '1'" class="btn btn-lg outline-primary rounded-circle">
            1
          </button>
          <button @click="passwordText += '2'" class="btn btn-lg outline-primary rounded-circle">
            2
          </button>
          <button @click="passwordText += '3'" class="btn btn-lg outline-primary rounded-circle">
            3
          </button>

          <button @click="passwordText += '4'" class="btn btn-lg outline-primary rounded-circle">
            4
          </button>

          <button @click="passwordText += '5'" class="btn btn-lg outline-primary rounded-circle">
            5
          </button>
          <button @click="passwordText += '6'" class="btn btn-lg outline-primary rounded-circle">
            6
          </button>
          <button @click="passwordText += '7'" class="btn btn-lg outline-primary rounded-circle">
            7
          </button>
          <button @click="passwordText += '8'" class="btn btn-lg outline-primary rounded-circle">
            8
          </button>
          <button @click="passwordText += '9'" class="btn btn-lg outline-primary rounded-circle">
            9
          </button>
          <button @click="passwordText = ''" class="btn btn-lg outline-primary rounded-circle">
            C
          </button>
          <button @click="passwordText += '0'" class="btn btn-lg outline-primary rounded-circle">
            0
          </button>
          <button @click="passwordText = passwordText.slice(0, -1)" class="btn btn-lg outline-primary rounded-circle">
            <ion-icon name="arrow-back-outline" style="font-size: 35px;" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import FormHeader from "../../components/FormHeader/FormHeader.vue";
import axios from "axios";
import router from "../../router/index.js";
import { toast } from "vue3-toastify";
import { setLoading } from "../../store/app.js";

const passwordText = ref("");
const users = ref([]);

onMounted(() => {
  getInformation();
  localStorage.removeItem("user");
});

const getInformation = async () => {
  setLoading(true);

  axios({
    method: "POST",
    data: {
      domain: localStorage.getItem("domain") ?? null,
    },
    url: "v2/auth/information",
    headers: {
      Authorization: localStorage.getItem("token") || null,
    },
  }).then(({ data }) => {
    if (data.success === true) {
      data.users.forEach((element) => {
        users.value.push(element);
      });
      setLoading(false);
    }
  });
};

const replaceWithAsterisk = (input) => {
  var result = "";

  for (var i = 0; i < passwordText.value.length; i++) {
    result += "*";
  }

  return result;
};

const loginUser = async () => {
  let user = users.value.find((user) => user.password == passwordText.value);
  passwordText.value = "";
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
    await router.push("/tables");
  } else {
    toast.error('Hatalı pin kodu girdiniz! Lütfen tekrar deneyin!', {
      theme: 'light',
      position: 'top-center',
      autoClose: 2000,
      dangerouslyHTMLString: true,
    });
  }
};


</script>

<style src="./PinLogin.scss" lang="scss" scoped></style>

<style scoped lang="scss">
.buttons {
  width: 100%;
  max-width: 250px !important;
  margin: 0px auto;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.buttons button {
  width: 65px;
  height: 65px;
  border-radius: 65px;
  padding: 0px;
  margin: 0px auto;

  border: 5px solid #2c2e43;
  font-size: 28px;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
}

.value-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  .login-btn {
    height: 55px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .value {
    background: white;
    color: #2c2e43;
    max-width: 200px;
    width: 100%;
    text-align: center;
    padding: 10px;
    height: 55px;

    border-radius: 12px 0px 0px 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    input {
      background-color: transparent;
      border: none;
      outline: none;

      color: black;
      font-size: 32px;
      text-align: center;
    }


  }

  button {
    height: 55px;
    background: white;
    color: #F22151;
    border: none !important;
    border-radius: 0px 12px 12px 0px;

    &:hover {
      border: none !important;
    }
  }
}
</style>