<template>
  <header class="app-header navbar navbar-expand-lg">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/tables">
        <img src="/img/nevapos-logo-white.png" style="height: 40px">
      </router-link>
      <button v-if="props.showSignedOut" class="navbar-toggler" type="button" @click="signedOut">
        <i class="bi bi-box-arrow-right"></i>
      </button>
      <button v-if="props.showSidebar" class="navbar-toggler" type="button" @click="toggleMenu">
        <i class="bi bi-basket"></i>
        <span v-if="cartItemCount > 0" class="position-absolute badge rounded-pill bg-danger-card basket-items">
          {{ cartItemCount }}
        </span>
      </button>
      <div :class="['navbar-collapse', { show: isMenuOpen }]">
        <div class="d-flex justify-content-end align-items-center">
          <button class="navbar-toggler" type="button" @click="toggleMenu">
            <i class="bi bi-x primary-text bold"></i>
          </button>
        </div>
        <ul class="navbar-nav">
          <li class="nav-item" :class="{ active: activeTab === 'cart' }" @click="setActiveTab('cart')">
            <a class="nav-link" href="#">
              <i class="bi bi-basket"></i>
              <div v-if="isMenuOpen">Sepet</div>
            </a>
          </li>
          <li class="nav-item" :class="{ active: activeTab === 'orders' }" @click="setActiveTab('orders')">
            <a class="nav-link" href="#">
              <i class="bi bi-qr-code-scan"></i>
              <div v-if="isMenuOpen">Adisyonlar</div>
            </a>
          </li>
        </ul>

        <div class="content" v-if="activeTab === 'cart'">
          <ul class="order-list">
            <div class="table-name">{{ tableDetailStore.table.name }}</div>
            <div v-if="tableDetailStore.cart.length === 0">Siparis Yok!</div>
            <template v-else>
              <span
                v-if="tableDetailStore.note.length > 0 && tableDetailStore.note != null && tableDetailStore.cart.length > 0"
                class="table-note">Not: {{
                  tableDetailStore.note }}</span>
              <CartItem v-for="item in tableDetailStore.cart" :key="item.id" :item="item" />
            </template>
          </ul>
          <div>
            <div v-if="calculateTotalPrice(false)" class="total-price">
              <div class="title-text bold">TOPLAM</div>
              <div class="primary-text semi-bold">
                {{ formatPrice(calculateTotalPrice(false)) }}
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn-primaryx" @click="saveOrder">Siparisi Olustur</button>
            </div>
          </div>
        </div>
        <div v-else-if="activeTab === 'orders'" class="content">
          <ul class="order-list">
            <div class="table-name">
              {{ tableDetailStore.table.name }}
            </div>
            <span
              v-if="tableDetailStore.note.length > 0 && tableDetailStore.note != null && !tableDetailStore.cart.length"
              class="table-note">Not: {{
                tableDetailStore.note }}</span>
            <div v-if="tableDetailStore.table.orders?.length === 0 || !tableDetailStore.table.orders">Siparis Yok!</div>
            <div v-else v-for="item in tableDetailStore.table.orders" :key="item.id">

              <li v-for="subItem in item.order_items" :key="`${subItem.id}_subItem`"
                :class="{ 'opacity': subItem.exclude_type == 1 || subItem.exclude_type == 2 || getIsPayed(subItem) }">
                <div class="order-content">
                  <div>
                    <div class="order-name"> x{{ subItem.amount }} {{ subItem.title }}</div>
                    <span v-if="subItem.product_notes && subItem.product_notes.length > 0" style="font-size: 14px;">Not:
                      {{ subItem.product_notes }}</span>
                    <div v-if="subItem.exclude_type == 1 || subItem.exclude_type == 2"
                      class="text-start px-0 p-0 badge text-dark">
                      {{ getExclude(subItem) }}
                    </div>


                    <span v-if="getIsPayed(subItem)" class="text-start px-0 p-0 badge text-dark">
                      Ödendi
                    </span>

                    <div class="extras">
                      <span v-if="subItem.portion == '0.5'">Az Porsiyon</span>
                      <span v-if="subItem.portion == '1.5'">x1.5 Porsiyon</span>
                      <span v-if="subItem.portion == '2'">Duble Porsiyon</span>
                      <span v-if="subItem.personal">Personel İndirimi</span>

                      <div class="mb-2" v-for="feature in subItem.features" :key="feature">
                        <span v-for="(option, i) in filteredOptions(feature)">
                          {{ option.name
                          }}<span v-if="filteredOptions(feature).length != i + 1">, </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center align-items-center" style="gap: 10px;">

                    <div>
                      <div class="price">{{ getPrice(subItem) }}</div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </ul>
          <div>
            <div v-if="calculateTotalPrice(true)" class="total-price">
              <div class="title-text bold">TOPLAM</div>
              <div class="primary-text semi-bold">
                {{ formatPrice(calculateTotalPrice(true)) }}
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn-primary" @click="printOrder">Adisyonu Yazdir</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>



</template>

<script setup>
import { ref, defineProps, computed, onMounted } from "vue";
import { toast } from "vue3-toastify";
import {
  tableDetailStore,
  addProductToTable,
  updateTable,
  updateProductNote,
} from "../../store/table-detail";
import formatPrice from "../../utils/formatPrice.ts";
import CartItem from "./CartItem.vue";
import { getCartTotal } from "../../store/table-detail";
import axios from "axios";
import { useRouter } from "vue-router";
import CreateNoteModal from "@/views/MenuView/CreateNoteModal/CreateNoteModal.vue";
import { onLongPress } from "@vueuse/core";
import { appStore } from "@/store/app";


const htmlRefHook = ref();

const isMenuOpen = ref(false);

const noteModal = ref(false);
const noteProductId = ref(null);

const router = useRouter();

const getIsPayed = (subItem) => {
  return paidPrice(subItem.id) && Number(paidPrice(subItem.id)) == Number(subItem.price) || ((getCartTotal() - tableDetailStore.payments.reduce((acc, pay) => acc + pay.total, 0) === 0) && tableDetailStore.cart.length);
}

const getExclude = (item) => {
  switch (item.exclude_type) {
    case 1:
      return `${ExcludeTypes[item.exclude_type]} Nedeni: ${item.exclude_note}`;
    case 2:
      return `${ExcludeTypes[item.exclude_type]} Nedeni: ${item.exclude_note}`;
    default:
      return "";
  }

}

const setNoteProductId = (payload) => {
  noteProductId.value = payload;
}

const closeNoteModal = () => {
  noteModal.value = false;
}

const getProductPaid = (id) => {
  let totalPaid = 0;
  let total = 0;
  let productPrice = 0;
  const products = [];

  tableDetailStore.payments.forEach((payment) => {
    payment.products.forEach(product => {
      if (!products.includes(product.id) && payment.products.filter(i => i.id === id).length) {
        total += product.singleTotal;
        products.push(product.id);
      }
      if (product.id === id) {
        productPrice = product.singleTotal
        totalPaid += payment.total
      }
    })
  })


  return (productPrice / total) * totalPaid
}

const handleSave = (note) => {
  updateProductNote({ productId: noteProductId.value, note });

  noteModal.value = false;
  noteProductId.value = null;
}

const getProductNote = computed(() => {
  if (noteProductId.value === null) {
    return "";
  }

  if (tableDetailStore.table.orders) {
    const found = tableDetailStore.table.orders[0].order_items.find((item) => item.product_id === noteProductId.value);
    if (found) {
      return found.product_notes ?? "";
    }

    return "";
  }
  return "";
});


const onLongPressProduct = () => {
  alert()
}

onLongPress(
  htmlRefHook,
  onLongPressProduct,
  {
    modifiers: {
      prevent: true,
      stop: true
    }
  }
)



const props = defineProps({
  showSidebar: {
    type: Boolean,
    default: false,
  },
  showSignedOut: {
    type: Boolean,
    default: false,
  },
});

const filteredOptions = (feature) => {
  return feature.options.filter((option) =>
    feature.selected.includes(option.id)
  );
};

const toggleProductNote = (productId) => {
  setNoteProductId(productId);
  noteModal.value = true;
}

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const calculateTotalPrice = (withAddition) => {
  if (withAddition) {
    return getCartTotal(withAddition) - tableDetailStore.payments.reduce((acc, pay) => acc + pay.total, 0);
  }
  return getCartTotal(false);
};


const activeTab = ref("cart");

const setActiveTab = (tab) => {
  activeTab.value = tab;
};

const paidPrice = (id) => {
  return getProductPaid(id);
}

const ExcludeTypes = {
  0: "",
  1: "İkram",
  2: "İptal"
}
const saveOrder = ({ withNavigate = true }) => {
  if (tableDetailStore.table?.orders?.length) {
    updateTable(withNavigate)
  } else {
    addProductToTable(withNavigate);
  }
};

const signedOut = async () => {
  localStorage.removeItem("token");
  localStorage.removeItem("domain");
  localStorage.removeItem("userData");
  localStorage.removeItem("user");
  location.reload();
  await router.push({ name: "UserLogin" });
};

const getPrice = (item) => {
  return formatPrice(item.total);
}

const printOrder = () => {
  appStore.loading = true;
  if (tableDetailStore.cart.length) {
    saveOrder({ withNavigate: false });
  }
  axios({
    url: "api/v2/area/status",
    method: "POST",
    data: {
      domain: localStorage.getItem("domain"),
      status: 3,
      waiter_type: 1,
      id: tableDetailStore.table.id
    }
  }).then((res) => {
    toast.success('Adisyon yazdırıldı', {
      theme: 'light',
      position: 'top-center',
      autoClose: 2000,
      dangerouslyHTMLString: true,
    });
    toggleMenu();
    appStore.loading = false;
    router.push('/tables');
  }).catch((e) => {
    toast.error('Adisyon yazdırılırken sistemde bir sorun oluştu!', {
      theme: 'light',
      position: 'top-center',
      autoClose: 2000,
      dangerouslyHTMLString: true,
    });
    toggleMenu();
    appStore.loading = false;
    console.log("e", e);
  })

};

const cartItemCount = computed(() => {
  return tableDetailStore.cart.reduce((total, item) => total + item.quantity, 0);
});


</script>

<style src="./AppHeader.scss" lang="scss"></style>

<style scoped lang="scss">
.extras {
  span {
    font-size: 15px;
  }

  span:not(:first-child) {
    &::before {
      content: ", "
    }
  }
}
</style>