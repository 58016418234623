import { createI18n } from "vue-i18n";
import TR from "./tr";
import EN from "./en";
import FR from "./fr";
import DE from "./de";
const i18n = createI18n({
  locale: 'tr',
  fallbackLocale: 'tr',
  legacy: false,
  messages: {
    tr: TR,
    en: EN,
    de: DE,
    FR: FR,
  }
})

export default i18n;