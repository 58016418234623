<template>
    <li ref="productRef">
        <div class="order-content">
            <div>
                <div>
                    <div class="order-name">{{ item.name }}</div>
                    <div class="price">{{ formatPrice(itemAbsolutePrice(item)) }}</div>
                </div>
                <span v-if="item.product_notes && item.product_notes.length > 0" style="font-size: 14px;">Not:
                    {{ item.product_notes }}</span>
                <div class="extras">
                    <span v-if="item.portion === '0.5'">Az Porsiyon</span>
                    <span v-if="item.portion === '1.5'">x1.5 Porsiyon</span>
                    <span v-if="item.portion === '2'">Duble Porsiyon</span>
                    <span v-if="item.personal">Personel İndirimi</span>
                    <div class="mb-2" v-for="feature in item.featureList" :key="feature">
                        <span v-for="(option, i) in filteredOptions(feature)">
                            {{ option.name
                            }}<span v-if="filteredOptions(feature).length != i + 1">, </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center" style="gap: 10px;">
                <div>
                    <div class="amount">
                        <ItemCounter :count="item.quantity" :cart-id="item.id" @increase="quantityIncreased"
                            @decrease="quantityDecreased" />
                    </div>
                </div>
                <button class="btn btn-primary delete-btn" @click="removeProduct(item.id)">
                    <i class="bi bi-x-lg"></i>
                </button>
            </div>
        </div>

    </li>

    <CreateNoteModal v-if="noteModal" :show="noteModal" @close="closeNoteModal" @on-save="handleSave"
        :value="getProductNote" />
</template>
<script setup>
import { decreaseProductsInCart, increaseProductsInCart, itemAbsolutePrice, removeProductFromCart, tableDetailStore, updateProductNote } from '@/store/table-detail';
import formatPrice from '../../utils/formatPrice.ts';
import ItemCounter from '../Counter/ItemCounter.vue';
import { computed, ref } from 'vue';
import { onLongPress } from '@vueuse/core';
import CreateNoteModal from '@/views/MenuView/CreateNoteModal/CreateNoteModal.vue';

const props = defineProps(['item'])

const productRef = ref();

const noteModal = ref(false);
const closeNoteModal = () => {
    noteModal.value = false;
}
const handleSave = (note) => {
    updateProductNote({ productId: props.item.raw_id, note });

    noteModal.value = false;
}
const getProductNote = computed(() => {
    const found = tableDetailStore.cart.find((item) => item.raw_id === props.item.raw_id);
    if (found) {
        return found.product_notes ?? "";
    }

    return "";
});

const onLongPressProduct = () => {
    noteModal.value = true;
}

onLongPress(
    productRef,
    onLongPressProduct,
    {
        modifiers: {
            prevent: true,
            stop: true
        }
    }
)

const filteredOptions = (feature) => {
    return feature.options.filter((option) =>
        feature.selected.includes(option.id)
    );
};


const quantityIncreased = (cartId, quantity) => {
    increaseProductsInCart(cartId, quantity);
};

const quantityDecreased = (cartId, quantity) => {
    if (quantity === 0) {
        removeProductFromCart(cartId);
    } else {
        decreaseProductsInCart(cartId, quantity);
    }
};

const removeProduct = (cartId) => {
    removeProductFromCart(cartId);
};

</script>