import { reactive } from 'vue';
import axios from 'axios';
import router from '../router/index.js';
import { setLoading, setMoveTableId } from './app';
import { fetchTableData } from './tables.js';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import unId from '../utils/uniqueId.ts';

const tableDetailStoreConst = {
  selectedIndex: 0,
  area_id: null,
  categories: [],
  products: [],
  table: {},
  payments: [],
  cart: [],
  selectedCartItems: [],
  selectedProductOnFeature: null,
  addedProductFeatures: [],
  selectedPortion: null,
  willMoveTableId: null,
  stateTable: [],
  note: '',
  customer: null,
  customers: [],
  customerCount: 0,
  isDivide: false,
  isCatering: false,
  isReturn: false,
  isBilling: false,
  selectedCategory: [],
  subProducts: null,
  showSubCategory: false,
  showParent: true,
  subCategory: [],
  tableSelectedIndex: 0,
};

export const tableDetailStore = reactive({ ...tableDetailStoreConst });

export const setAddedProductFeatures = (payload = null) => {
  tableDetailStore.addedProductFeatures = JSON.parse(JSON.stringify(payload));
};

export const resetTableDetail = () => {
  if (!tableDetailStore.isDivide || !tableDetailStore.isCatering || !tableDetailStore.isReturn) {
    tableDetailStore.cart = [];
  }
  tableDetailStore.table = {};
};


export const getAdditionTotal = () => {

  if (tableDetailStore.table?.orders?.length) {

    return tableDetailStore.table.orders[0].order_items?.reduce((total, item) => {
      let quantity;
      if (item) {
        quantity = item.quantity - ((item.exclude ?? item.exclude ?? 0))
      } else {
        quantity = item.quantity
      }
      let featurePrice = 0


      if (item.featureList) {
        featurePrice = item.featureList.map(feature => {
          let optionsAmount = feature.options.filter(option => feature.selected.includes(option.id)).map(option => Number(option.price)).reduce((a, b) => a + b, 0)
          return optionsAmount
        }).reduce((a, b) => a + b, 0)
      }
      let price = getPriceByPortion(item, { quantity, featurePrice });


      if (parseFloat(item.small_price) && parseFloat(item.personal_price) && item.small_price === item.personal_price) price = parseFloat(item.personal_price);
      if (item.exclude) price = 0;




      return total + price;
    }, 0);
  }
  return null;
}

export const getPriceByPortion = (item, { quantity, featurePrice }) => {
  switch (item.portion) {
    case "0.5":
      return ((Number(item.small_price) * Number(item.portion)) + featurePrice) * Number(quantity);
    case "1.5":
      return ((Number(item.price) * 1.5) + featurePrice) * Number(quantity);
    case "2":
      return ((Number(item.price) * 2) + featurePrice) * Number(quantity);
    default:
      return (Number(item.price) + featurePrice) * Number(quantity);
  }
}
export const getCartTotal = (withAddition = true) => {
  const cartTotal = tableDetailStore.cart.reduce((total, item) => {
    let quantity;
    if (item) {
      quantity = item.quantity - ((item.exclude ?? item.exclude ?? 0))
    } else {
      quantity = item.quantity
    }

    let featurePrice = 0
    if (item.featureList) {
      featurePrice = item.featureList.map(feature => {
        let optionsAmount = feature.options.filter(option => feature.selected.includes(option.id)).map(option => Number(option.price)).reduce((a, b) => a + b, 0)
        return optionsAmount
      }).reduce((a, b) => a + b, 0)
    }

    const personalPrice = item.personal == 1 ? Number(item.personal_price) * Number(item.quantity) : 0;

    var price = 0;

    switch (item.portion) {
      case "0.5":
        price = (parseFloat(item.small_price) + featurePrice) * item.quantity;
        break;
      case "1.5":
        price = (parseFloat(item.price) + featurePrice) * item.quantity;
        break;
      case "2":
        price = (parseFloat(item.price) + featurePrice) * item.quantity;
        break;

      default:
        price = (parseFloat(item.price) + featurePrice) * item.quantity;
        break;
    }

    console.log("item.price", item.price);
    console.log("item.1.5", item.price * 1.5);
    console.log("feature", featurePrice);
    console.log("quantity", item.quantity);




    if (parseFloat(item.small_price) && parseFloat(personalPrice) && item.small_price === personalPrice) price = parseFloat(personalPrice);
    if (item.exclude) price = 0;

    return total + (price - personalPrice);
  }, 0);
  if (withAddition) {
    return (getAdditionTotal() ?? 0) + cartTotal;
  }
  return cartTotal;
};

export const updateProductNote = ({ productId, note }) => {
  const cart = tableDetailStore.cart;


  tableDetailStore.cart = cart.map((e) => {
    if (e.raw_id === productId) {
      return {
        ...e,
        product_notes: note
      }
    }
    return e;
  })


}

export const addProductToTable = (withNavigate = true) => {
  tableDetailStore.willMoveTableId = null;
  tableDetailStore.isDivide = false;
  let cart = parseCart();
  if (cart.length > 0) {
    setLoading(true);
    cart = cart.map((item) => {
      return {
        ...item,
        exclude_type: 0,
      };
    });
    const personalId = JSON.parse(localStorage.getItem("user")).id
    axios({
      method: 'POST',
      url: 'api/v2/area/add_product_to_table',
      data: {
        domain: localStorage.getItem('domain'),
        masa_id: tableDetailStore.table.id,
        products: cart,
        total: getCartTotal(),
        notes: tableDetailStore.note,
        customerCount: tableDetailStore.customerCount,
        waiter_id: personalId
      },
    })
      .then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          tableDetailStore.customer = null;
          tableDetailStore.selectedCartItems = [];
          tableDetailStore.selectedIndex = 0;
          if (withNavigate) {
            router.push('/tables');
          }
        }
      })
      .catch((e) => {
        console.log('err', e);
        setLoading(false);
      });
  } else {
    toast('UYARI: Adisyona ürün eklemelisiniz.', {
      theme: 'light',
      type: 'error',
      position: 'top-center',
      autoClose: 2000,
      dangerouslyHTMLString: true,
    });
  }
};

export const updateTable = (withNavigate = true) => {
  console.log("parseOrderItems", parseOrderItems());

  let updateData = {
    domain: localStorage.getItem("domain"),
    table_id: tableDetailStore.table.id,
    products: [...parseOrderItems(), ...parseCart()],
    total: getCartTotal() - tableDetailStore.payments.reduce((acc, pay) => acc + pay.total, 0),
    notes: tableDetailStore.note,
  }

  console.log("parseOrderItems", parseOrderItems());


  let payments = tableDetailStore.payments.map((e) => ({
    ...e,
    products: e.products.map(product => {
      return {
        id: product.raw_id,
        quantity: product.quantity,
        singleTotal: product.singleTotal,
      }
    }),
    customer_id: tableDetailStore.customer?.id || 0
  }))
  if (payments.length > 0) {
    updateData.payments = payments
  }

  axios({
    method: "POST",
    url: "api/v2/area/table_order_update",
    data: updateData,
  })
    .then(() => {
      setLoading(false);
      if (withNavigate) {
        router.push("/tables");
      }
    })
    .catch((e) => {
      setLoading(false);
      console.log("err", e);
    });
}

export const setReservation = (notes) => {
  axios({
    url: 'api/v2/area/reservation',
    method: 'POST',
    data: {
      domain: localStorage.getItem('domain'),
      id: tableDetailStore.table.id,
      status: 2,
      reservation: notes,
    },
  })
    .then((res) => {
      if (res.data.success === true) {
        router.push('/tables');
      }
    })
    .catch((e) => {
      console.log('err', e);
    });
};

export const changeTableStatus = (status) => {
  axios({
    url: 'api/v2/area/status',
    method: 'POST',
    data: {
      domain: localStorage.getItem('domain'),
      id: tableDetailStore.table.id,
      status: status,
    },
  })
    .then((res) => {
      if (res.data.success === true) {
        router.push('/tables');
      }
    })
    .catch((e) => {
      console.log('err', e);
    });
};

export const addToCartProduct = (product) => {

  product.personal = product.personal ?? 0;

  let uniqueId = unId()
  let newCartItem = {
    raw_id: uniqueId,
    ...product,
    product_id: product.id,
    id: uniqueId,
    newlyAdded: true,
    personal: product.personal ?? 0
  }


  const found = tableDetailStore.cart.find((e) => e.product_id == product.id);
  if (!found) {
    tableDetailStore.cart.push(newCartItem);
    setAddedProductFeatures([]);
    return;
  }





  if (product.portion == "1") {
    const found = tableDetailStore.cart.find((e) => e.product_id === product.id && e.portion == "1");

    if (!found) {
      tableDetailStore.cart.push(newCartItem);
      setAddedProductFeatures([]);
      return;
    }



    if (product.personal == 0) {
      const found = tableDetailStore.cart.filter((e) => e.featureList === undefined).find((e) => e.product_id === product.id && e.portion == "1" && e.personal == 0);
      if (!found || product.featureList) {
        tableDetailStore.cart.push(newCartItem);
        setAddedProductFeatures([]);
      } else {
        incrementCartProduct({
          quantity: product.quantity,
          portion: "1",
          productId: product.id,
          personal: product.personal,
          featureList: (e) => e.featureList === undefined
        });
      }
    }

    if (product.personal == 1) {
      const found = tableDetailStore.cart.find((e) => e.product_id === product.id && e.portion == "1" && e.personal == 1);
      if (!found || product.featureList) {
        tableDetailStore.cart.push(newCartItem);
        setAddedProductFeatures([]);
      } else {
        incrementCartProduct({
          quantity: product.quantity,
          portion: "1",
          productId: product.id,
          personal: product.personal,
          featureList: (e) => e.featureList === undefined
        });
      }
    }


    setAddedProductFeatures([]);
    return;
  }

  if (product.portion == "0.5") {
    const found = tableDetailStore.cart.find((e) => e.product_id === product.id && e.portion == "0.5");

    if (!found) {
      tableDetailStore.cart.push(newCartItem);
      setAddedProductFeatures([]);
      return;
    }



    if (product.personal == 0) {
      const found = tableDetailStore.cart.find((e) => e.product_id === product.id && e.portion == "0.5" && e.personal == 0);
      if (!found || product.featureList) {
        tableDetailStore.cart.push(newCartItem);
        setAddedProductFeatures([]);
      } else {
        incrementCartProduct({
          quantity: product.quantity,
          portion: "0.5",
          productId: product.id,
          personal: product.personal,
          featureList: (e) => e.featureList === undefined
        });
      }
    }

    if (product.personal == 1) {
      const found = tableDetailStore.cart.find((e) => e.product_id === product.id && e.portion == "0.5" && e.personal == 1);
      if (!found || product.featureList) {
        tableDetailStore.cart.push(newCartItem);
        setAddedProductFeatures([]);
      } else {
        incrementCartProduct({
          quantity: product.quantity,
          portion: "0.5",
          productId: product.id,
          personal: product.personal,
          featureList: (e) => e.featureList === undefined
        });
      }
    }


    setAddedProductFeatures([]);
    return;
  }
  if (product.portion == "1.5") {
    const found = tableDetailStore.cart.find((e) => e.product_id === product.id && e.portion == "1.5");

    if (!found) {
      tableDetailStore.cart.push(newCartItem);
      setAddedProductFeatures([]);
      return;
    }



    if (product.personal == 0) {
      const found = tableDetailStore.cart.find((e) => e.product_id === product.id && e.portion == "1.5" && e.personal == 0);
      if (!found || product.featureList) {
        tableDetailStore.cart.push(newCartItem);
        setAddedProductFeatures([]);
      } else {
        incrementCartProduct({
          quantity: product.quantity,
          portion: "1.5",
          productId: product.id,
          personal: product.personal,
          featureList: (e) => e.featureList === undefined
        });
      }
    }

    if (product.personal == 1) {
      const found = tableDetailStore.cart.find((e) => e.product_id === product.id && e.portion == "1.5" && e.personal == 1);
      if (!found || product.featureList) {
        tableDetailStore.cart.push(newCartItem);
        setAddedProductFeatures([]);
      } else {
        incrementCartProduct({
          quantity: product.quantity,
          portion: "1.5",
          productId: product.id,
          personal: product.personal,
          featureList: (e) => e.featureList === undefined
        });
      }
    }


    setAddedProductFeatures([]);
    return;
  }
  if (product.portion == "2") {
    const found = tableDetailStore.cart.find((e) => e.product_id === product.id && e.portion == "2");

    if (!found) {
      tableDetailStore.cart.push(newCartItem);
      setAddedProductFeatures([]);
      return;
    }



    if (product.personal == 0) {
      const found = tableDetailStore.cart.find((e) => e.product_id === product.id && e.portion == "2" && e.personal == 0);
      if (!found || product.featureList) {
        tableDetailStore.cart.push(newCartItem);
        setAddedProductFeatures([]);
      } else {
        incrementCartProduct({
          quantity: product.quantity,
          portion: "2",
          productId: product.id,
          personal: product.personal,
          featureList: (e) => e.featureList === undefined
        });
      }
    }

    if (product.personal == 1) {
      const found = tableDetailStore.cart.find((e) => e.product_id === product.id && e.portion == "2" && e.personal == 1);
      if (!found || product.featureList) {
        tableDetailStore.cart.push(newCartItem);
        setAddedProductFeatures([]);
      } else {
        incrementCartProduct({
          quantity: product.quantity,
          portion: "2",
          productId: product.id,
          personal: product.personal,
          featureList: (e) => e.featureList === undefined
        });
      }
    }


    setAddedProductFeatures([]);
    return;
  }



};
const incrementCartProduct = ({ quantity, portion, productId, personal, featureList }) => {
  tableDetailStore.cart = tableDetailStore.cart.map((e) => {
    if (e.product_id === productId && e.portion == portion && e.personal == personal && featureList && featureList(e)) {
      return {
        ...e,
        quantity: e.quantity + quantity
      }
    }

    return e;
  })
}

export const increaseProductsInCart = (id, quantity) => {
  tableDetailStore.cart = tableDetailStore.cart.map((e) => {
    if (e.id === id) {
      return {
        ...e,
        quantity: quantity,
      };
    }
    return e;
  });
};

export const decreaseProductsInCart = (id, quantity) => {
  tableDetailStore.cart = tableDetailStore.cart.map((e) => {
    if (e.id === id) {
      return {
        ...e,
        quantity: e.quantity - 1,
      };
    }
    return e;
  });
};

export const removeProductFromCart = (id) => {
  tableDetailStore.cart = tableDetailStore.cart.filter((e) => e.id !== id);
};

export const fetchMenu = () => {
  setLoading(true);
  axios({
    method: 'GET',
    url: 'api/v2/category/list',
    params: {
      domain: localStorage.getItem('domain'),
    },
  })
    .then((response) => {
      if (response.data.success === true) {
        tableDetailStore.categories = response.data.categories;
        tableDetailStore.products = response.data.categories[tableDetailStore.selectedIndex].products;
        setLoading(false);
      }
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
};

export const fetchTable = (id) => {
  tableDetailStore.cart = [];
  tableDetailStore.payments = [];
  setLoading(true);
  axios({
    method: 'GET',
    url: 'api/v2/area/show',
    params: {
      id: id,
      domain: localStorage.getItem('domain'),
    },
  })
    .then((response) => {
      if (response.data.success === true) {
        var table = response.data.table[0];

        if (table.orders.length) {
          table.orders[0].order_items = table.orders[0].order_items.map((e, i) => ({
            ...e,
            small_price: e.total,
            personal_price: e.total
          }))
        }

        tableDetailStore.table = table;
        tableDetailStore.note = response.data.table[0].orders[0].notes ?? "";


        tableDetailStore.payments = response.data.table[0].orders[0].order_payments?.map((e) => {
          let products = typeof e.products === 'string' ? JSON.parse(e.products) : e.products

          if (products && products.length > 0) {
            products = products.map(product => {


              return {
                ...product,
                raw_id: product.id,
                id: product.id
              }
            })
          }
          return {
            title: e.title,
            products: products,
            payment_type: e.type,
            total: parseFloat(e.total),
            temporary_id: unId(),
          }
        }) ?? [];

        setLoading(false);
      }
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
};

export const parseCart = () => {
  return tableDetailStore.cart.map((e) => {
    return {
      id: e.raw_id,
      product_name: e.name,
      price: e.price,
      quantity: e.quantity,
      exclude: e.exclude || 0,
      exclude_type: 0,
      exclude_note: e.exclude_note || '',
      features: e.featureList || null,
      portion: e.portion || '1',
      total: itemAbsolutePrice(e),
      product_id: e.product_id,
      personal: 0,
      notes: e.product_notes ?? ""
    };
  });
};

const parseOrderItems = () => {
  return tableDetailStore.table.orders[0].order_items.map(e => {


    return {
      id: unId(),
      product_name: e.title,
      price: e.price,
      quantity: e.amount,
      exclude: e.exclude || 0,
      exclude_type: e.exclude_type,
      exclude_note: e.exclude_note || '',
      features: e.featureList || null,
      portion: e.portion || '1',
      total: itemAbsolutePrice(e),
      product_id: e.id,
      personal: 0,
      notes: e.product_notes ?? ""
    };
  })
}

export const itemAbsolutePrice = (item, single = false) => {

  let featurePrice = 0

  let portion = item.portion ? parseFloat(item.portion) : 1

  if (item.featureList) {
    featurePrice = item.featureList.map(feature => {
      let optionsAmount = feature.options.filter(option => feature.selected.includes(option.id)).map(option => Number(option.price)).reduce((a, b) => a + b, 0)
      return optionsAmount
    }).reduce((a, b) => a + b, 0)

  }


  const result = item.portion === '0.5' ? parseFloat(item.small_price) * (single ? 1 : itemAbsoluteQuantity(item)) : Number((Number(item.price) + featurePrice) * (single ? 1 : itemAbsoluteQuantity(item)))


  const personalPrice = item.personal == 1 ? Number(item.personal_price) * Number(item.quantity) : 0;

  const total = Number(result) - personalPrice;







  return Number(total);
}

export const itemAbsoluteQuantity = (item) => {
  let cartItem = tableDetailStore.cart.find((cartItem) => cartItem.id == item.id);
  if (cartItem) {
    return cartItem.quantity - (checkItemPaidQuantity(cartItem.id) + (cartItem.exclude ?? item.exclude ?? 0));
  } else {
    return item.quantity;
  }
};

export const checkItemPaidQuantity = (id) => {
  let payments = tableDetailStore.payments.filter(
    (payment) => payment.products.length > 0 && checkPaymentProductIsJson(payment).id == id
  );
  if (payments.length > 0) {
    let paidTotal = payments.reduce((a, b) => a + b.total, 0);
    if (paidTotal != 0) {
      return parseInt(paidTotal / checkPaymentProductIsJson(payments[0]).singleTotal);
    }
  }
  return 0;
};

const showSuccessToast = () => {
  toast.success("Masayı taşıma işlemi başarılı", {
    theme: "light",
    position: "top-center",
    autoClose: 2000,
    dangerouslyHTMLString: true,
  });
};

export const changeTable = async ({ willChangeTableId, toTableId }) => {
  try {
    setMoveTableId(null);

    setLoading(true);
    await axios({
      method: "POST",
      url: "api/v2/area/table_change",
      data: {
        domain: localStorage.getItem('domain'),
        table_id: willChangeTableId,
        change_id: toTableId
      },
    });

    setLoading(false);

    showSuccessToast();


    await fetchTableData();
  } catch (error) {

    setLoading(false);
    console.log("err", e);
  }

};

export const cancelReservation = () => {

  const waiterData = localStorage.getItem("user");
  let waiter = JSON.parse(waiterData);

  axios({
    url: "api/v2/area/status",
    method: "POST",
    data: {
      domain: localStorage.getItem("domain"),
      id: tableDetailStore.table.id,
      status: 0,
      waiter_type: waiter.type,
    },
  })
    .then((res) => {
      if (res.data.success === true) {
        router.push(`/tables`);
      }
    })
    .catch((e) => {
      console.log("err", e);
    });
}