<template>
  <div>
    <div class="menu-view">
      <AppHeader :show-sidebar="true" />
      <ScrollableItems :items="menu.categories" @selected="handleMenuSelected" />
      <ScrollableItems v-if="selectedCategorySubcategories" :items="selectedCategorySubcategories"
        @selected="handleSubMenuSelected" />
      <ScrollableItems v-if="selectedCategorySubSubcategories" :items="selectedCategorySubSubcategories"
        @selected="handleSubSubMenuSelected" />
      <div class="container-fluid menu-view-inner">

        <MenuItem v-for="product in selectedCategoryProducts" :key="product.id" :product="product"
          :categories="selectedCategorySubcategories" />
      </div>
      <AppFooter :footer-items="items" @item-clicked="handleItemClicked" :grid="2" />
    </div>
    <ReservationModal :show="modalVisible" @close="closeModal" />
    <CreateNoteModal :show="noteModal" @close="closeNoteModal" @on-save="handleSave" />
    <MoveTableModal :show="moveTableModalVisible" @close="closeMoveTableModal" @success="showSuccessToast" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import AppHeader from "../../components/AppHeader/AppHeader.vue";
import ScrollableItems from "../../components/ScrollableItems/ScrollableItems.vue";
import MenuItem from "../../components/MenuItem/MenuItem.vue";
import AppFooter from "../../components/AppFooter/AppFooter.vue";
import axios from "axios";
import { setLoading } from "../../store/app.js";
import ReservationModal from "./ReservationModal/ReservationModal.vue";
import MoveTableModal from "./MoveTableModal/MoveTableModal.vue";
import CreateNoteModal from "./CreateNoteModal/CreateNoteModal.vue";
import router from "../../router/index";
import { cancelReservation, fetchTable, tableDetailStore } from "../../store/table-detail";
import { toast } from "vue3-toastify";

const menu = ref({
  categories: [],
  products: [],
});
const selectedCategory = ref(null);
const selectedCategoryProducts = ref([]);
const selectedCategorySubcategories = ref([]);
const selectedCategorySubSubcategories = ref([]);
const modalVisible = ref(false);
const noteModal = ref(false);
const moveTableModalVisible = ref(false);

const tableId = router.currentRoute.value.params.id;

onMounted(async () => {
  tableDetailStore.note = "";
  await fetchMenu();
  await fetchTable(tableId);
});

const handleSave = (payload) => {
  tableDetailStore.note = payload;
}

const fetchMenu = () => {
  setLoading(true);

  axios({
    url: "api/v2/category/all",
    method: "GET",
    params: {
      domain: localStorage.getItem("domain"),
    },
  })
    .then((res) => {
      if (res.status === 200) {

        const categories = res.data.categories;

        const categoryMap = categories.reduce((map, category) => {
          if (category.parent_id === 0) {
            map[category.id] = { ...category, subcategories: [] };
          }
          return map;
        }, {});

        categories.forEach((category) => {
          if (category.parent_id !== 0 && categoryMap[category.parent_id]) {
            category["subcategories"] = []
            categoryMap[category.parent_id].subcategories.push(category);
          }

          let subcat;
          if (category.parent_id !== 0 && !categoryMap[category.parent_id]) {

            var filtered = categories.filter(cat => cat.id === category.parent_id);

            subcat = filtered.length > 0 ? filtered[0].parent_id : null;


            if (subcat) {

              categoryMap[subcat]?.subcategories.filter(sub => sub.id === category.parent_id)[0]?.subcategories.push(category);
            }
          }
        });

        menu.value.categories = Object.values(categoryMap);


        selectedCategory.value = menu.value.categories[0];
        selectedCategoryProducts.value = menu.value.categories[0].products;

        handleMenuSelected(menu.value.categories[0].id);

        if (selectedCategorySubcategories.value.length) {
          handleSubMenuSelected(selectedCategorySubcategories.value[0].id);
        }
      }
      setLoading(false);
    })
};

const closeNoteModal = () => {
  noteModal.value = false;
}

const handleMenuSelected = (categoryId) => {
  const category = menu.value.categories.find((cat) => cat.id === categoryId);
  if (category) {
    selectedCategory.value = category;
    selectedCategorySubcategories.value = category.subcategories;
    selectedCategorySubSubcategories.value = selectedCategorySubcategories.subcategories;
    selectedCategoryProducts.value = category.products;
    if (category.subcategories.length > 0) {
      selectedCategoryProducts.value = category.subcategories[0].products;
    } else if (selectedCategorySubcategories[0]?.subcategories?.length > 0) {
      selectedCategoryProducts.value = selectedCategorySubcategories[0]?.subcategories[0].products;
    }
  }
};

const handleSubMenuSelected = (subcategoryId) => {
  const subcategory = selectedCategorySubcategories.value.find(
    (subcat) => subcat.id === subcategoryId
  );

  if (subcategory.subcategories?.length) {
    selectedCategorySubSubcategories.value = subcategory.subcategories
    selectedCategoryProducts.value = selectedCategorySubSubcategories[0]?.products
  } else selectedCategoryProducts.value = subcategory.products;
};

const handleSubSubMenuSelected = (subsubcategoryId) => {
  const subsubcategory = selectedCategorySubSubcategories.value?.find(
    (subsubcat) => subsubcat.id === subsubcategoryId
  );
  selectedCategoryProducts.value = subsubcategory.products;
};

const showSuccessToast = () => {
  toast.success("Masayı taşıma işlemi başarılı", {
    theme: "light",
    position: "top-center",
    autoClose: 2000,
    dangerouslyHTMLString: true,
  });
};

const handleItemClicked = (type) => {
  if (type === "reservation") {
    modalVisible.value = true;
  } else if (type === "note") {
    noteModal.value = true;
  } else if (type === 'cancel-reservation') {
    cancelReservation();
  } else if (type === "cancel") {
    router.push("/tables");
  }
};

const changeTableStatus = () => {
  changeTable();
};

const closeMoveTableModal = () => {
  moveTableModalVisible.value = false;
};

const closeModal = () => {
  modalVisible.value = false;
};

const items = computed(() => {
  var result = [];

  if (tableDetailStore.table.status === 2) {
    result.push({
      icon: "bi bi-x-lg",
      text: "Rezrvsyon. İptal",
      type: "cancel-reservation"
    })
  } else {
    result.push({
      icon: "bi bi-calendar4-week",
      text: "Rezervasyon",
      type: "reservation",
    })
  }

  result.push(...[

    {
      icon: "bi bi-journals",
      text: "Not Ekle",
      type: "note",
    },
    {
      icon: "bi bi-arrow-90deg-left",
      text: "Geri",
      type: "cancel",
    },
  ]);

  return result;
});
</script>

<style src="./MenuView.scss" lang="scss"></style>
