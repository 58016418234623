<template>
  <div v-if="props.show" class="c-modal">
    <div class="c-modal-content c-card">
      <div class="row mb-4">
        <div class="col-12">
          <div class="c-close" @click="close">&times;</div>
          <h3 class="mt-2 mb-4 center">Notlar</h3>
          <div class="form-group">
            <textarea v-model="note" type="text" class="form-control" id="name" placeholder="Not Ekle"
              style="padding: 15px;" rows="5" />
          </div>
          <div class="form-group">
            <button class="btn btn-primary btn-add" @click="saveNote">Kaydet</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref, onMounted, watch } from 'vue';

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  value: {
    type: String,
    required: false
  },
  isResetNote: {
    type: Boolean,
    default: true
  }
});

const note = ref("");

onMounted(() => {

  note.value = props.value;

});

const saveNote = () => {
  emit('onSave', note.value);
  close();
};

const emit = defineEmits(['close', 'onSave']);

const close = () => {
  emit('close');
};
</script>
<style src="./CreateNoteModal.scss" lang="scss"></style>
