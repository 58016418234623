import axios from 'axios';
import { reactive } from 'vue';
import { setLoading } from './app';
import { resetTableDetail } from './table-detail';

export const tableStore = reactive({
    areas: [],
    selectedArea: null,
    selectedAreaTables: [],
    showSearchInput: false,
    searchQuery: "",
    displayedTables: [],
    queryTables: []
});

export const closeSearchInput = () => {
    tableStore.searchQuery = "";
    tableStore.queryTables = [];
    tableStore.showSearchInput = false;
}

export const getQueriedTables = () => {
    const query = tableStore.searchQuery;
    console.log("tablestore", tableStore.areas);

    const allTablesData = tableStore.areas.map((e) => e.tables).map((e) => e);

    var result = [];

    for (let index = 0; index < allTablesData.length; index++) {
        const data = allTablesData[index];
        result.push(...data);
    }



    tableStore.queryTables = result.filter((e) => e.name.toLowerCase().includes(query.toLowerCase()));

}


export const fetchTableData = async () => {
    try {
        setLoading(true);
        const response = await axios({
            method: "GET",
            url: "api/v2/area/list",
            params: {
                domain: localStorage.getItem("domain"),
            },
        });



        if (response.data.success === true) {
            tableStore.areas = response.data.areas;
            if (tableStore.areas.length > 0) {
                tableStore.selectedArea = tableStore.areas[0].id;
                tableStore.selectedAreaTables = tableStore.areas[0].tables;
                tableStore.displayedTables = tableStore.selectedAreaTables;
            }
            resetTableDetail();
        }
        setLoading(false);

    } catch (error) {
        setLoading(false);
    }
};

export const handleAreaSelected = (areaId) => {
    const area = tableStore.areas.find((a) => a.id === areaId);
    if (area) {
        tableStore.selectedAreaTables = area.tables;
        tableStore.displayedTables = tableStore.selectedAreaTables;
    }
};

export const handleItemClicked = (type) => {
    if (type === "all-tables") {
        tableStore.displayedTables = tableStore.selectedAreaTables;
    } else if (type === "open-tables") {
        tableStore.displayedTables = tableStore.selectedAreaTables.filter(
            (table) => table.status === 0
        );
    } else if (type === "search-table") {
        tableStore.showSearchInput = true;
    }
};

export const searchTables = () => {
    tableStore.displayedTables = tableStore.selectedAreaTables.filter((table) =>
        table.name.toLowerCase().includes(tableStore.searchQuery.toLowerCase())
    );
};