<template>
  <div v-if="props.show" class="c-modal">
    <div class="c-modal-content c-card">
      <div class="row mb-4">
        <div class="col-12">
          <div class="c-close" @click="close">&times;</div>
          <h3 class="mt-2 mb-4 center">Masayı Taşı</h3>
          <div class="form-group">
            <label for="table">Masa Seçin</label>
            <select v-model="selectedTable" class="form-control" id="table">
              <optgroup v-for="area in areas" :label="area.name" :key="area.id">
                <option
                  v-for="table in area.tables.filter((table) => table.status === 0)"
                  :value="table.id"
                  :key="table.id"
                >
                  {{ table.name }}
                </option>
              </optgroup>
            </select>
          </div>
          <div v-if="warningMessage" class="alert">
            {{ warningMessage }}
          </div>
          <div class="form-group">
            <button class="btn btn-primary btn-add" @click="moveTable">Kaydet</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { defineProps, defineEmits, onMounted } from "vue";
import axios from "axios";
import router from "../../../router";
import { setReservation, changeTable } from "../../../store/table-detail";

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
});

const areas = ref([]);
const selectedTable = ref(null);
const warningMessage = ref("");

const emits = defineEmits(["close, success"]);

const close = () => {
  emits("close");
};

const fetchData = () => {
  axios({
    method: "GET",
    url: "api/v2/area/list",
    params: {
      domain: localStorage.getItem("domain"),
    },
  })
    .then((response) => {
      if (response.data.success === true) {
        areas.value = response.data.areas;
        // Set the initial selected table to the first table with status 0
        const firstAvailableTable = areas.value
          .flatMap((area) => area.tables)
          .find((table) => table.status === 0);
        if (firstAvailableTable) {
          selectedTable.value = firstAvailableTable.id;
        }
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

onMounted(() => {
  fetchData();
});

const moveTable = async () => {
  if (selectedTable.value) {
    await changeTable(selectedTable.value);
    close();

  } else {
    console.error("Lutfen bir masa secin.");
  }
};
</script>

<style src="./MoveTableModal.scss" lang="scss"></style>
