<script setup>
/* eslint-disable */
import { onMounted, watch, ref, onUnmounted } from 'vue';
import AppLoading from './components/AppLoading.vue';
import { appStore, setMoveTableId } from './store/app.js';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import router from './router';
import Pusher from 'pusher-js';
import { useRoute } from 'vue-router';


const userData = ref(localStorage.getItem('userData'));
let user = JSON.parse(userData.value);
const pusher = new Pusher('2cdbc34e236d4c107411', {
  cluster: 'eu',
  encrypted: true,
});
onUnmounted(() => {
  pusher.unsubscribe('sinerPOSPusher_' + user['code']);
  pusher.disconnect();
});
onMounted(() => {
  const channel = pusher.subscribe("sinerPOSPusher_" + user["code"]);

});

const route = useRoute()
watch(route, (to) => {
  setMoveTableId(null);
}, { flush: 'pre', immediate: true, deep: true })

watch(

);
</script>
<template>
  <div>
    <app-loading v-if="appStore.loading" />
    <router-view></router-view>
  </div>
</template>
