<template>
  <div class="scrollable-list">
    <button
      class="btn outline-primary item"
      v-for="item in items"
      :key="item.id"
      :class="{ active: selectedItem === item.id }"
      @click="selectItem(item.id)"
    >
      {{ item.name }}
    </button>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, onMounted } from 'vue';
import { tableDetailStore } from '../../store/table-detail.js';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  type: {
    type: String,
    required: false,
  },
});

onMounted(() => {
  if (tableDetailStore.table.area_id && props.type === 'area') {
    selectedItem.value = tableDetailStore.table.area_id;
    emitItemSelected('selected', selectedItem.value);
  }
});

const emitItemSelected = defineEmits(['selected']);

const selectedItem = ref(props.items.length > 0 ? props.items[0].id : null);

const selectItem = (itemId) => {
  selectedItem.value = itemId;
  emitItemSelected('selected', itemId);
};

watch(
  () => props.items,
  (newItems) => {
    if (newItems.length > 0 && !selectedItem.value) {
      selectedItem.value = newItems[0].id;
    }
  }
);
</script>

<style src="./ScrollableItems.scss" lang="scss"></style>
