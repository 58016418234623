import { createApp } from 'vue';
import { createDeviceDetector } from 'next-vue-device-detector';
import App from './App.vue';
import router from './router/index.js';
import Vue3Toastify from 'vue3-toastify';
import Vue3TouchEvents from 'vue3-touch-events';
import i18n from './i18n/index';
import './axios.js';
// import Logger from "./components/Logger/index.vue";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import './style.scss';

export const device = createDeviceDetector();
createApp(App)
  .use(router)
  .use(device)
  .use(Vue3Toastify)
  .use(i18n)
  .use(Vue3TouchEvents)
  // .component('Logger', Logger)
  .mount('#app');
