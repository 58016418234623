export default function auth({ to, next, router }) {
  const token = localStorage.getItem('token') ?? null;
  const user = localStorage.user ? JSON.parse(localStorage.getItem('user')) : null;
  const canAuth = to.fullPath === '/pin-login' || (user && to.meta.auth && to.meta.auth.includes(user.type));
  if (!token) {
    if (to.fullPath === '/') {
      return next();
    } else {
      return router.push({ name: 'UserLogin' });
    }
  } else if (!canAuth) {
    return router.push({ name: 'PinLogin' });
  } else {
    return next();
  }
}
