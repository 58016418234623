<template>
  <div class="tables-view">
    <AppHeader :show-sidebar="false" :show-signed-out="true" />
    <ScrollableItems :items="tableStore.areas" type="area" @selected="handleAreaSelected" />
    <div class="container-fluid tables-view-inner">
      <TableItem v-for="table in getTables" :key="table.id" :table="table" />
    </div>
    <input v-if="tableStore.showSearchInput" type="text" v-model="tableStore.searchQuery"
      @input="tableStore.searchTables" placeholder="Masa numarasina gore ara" class="form-control search-input" />
    <button v-if="tableStore.showSearchInput" @click="closeSearchInput()" class="btn btn-danger close-input">
      <i class="fa fa-times"></i>
    </button>
    <AppFooter :footer-items="items" @item-clicked="handleItemClicked" />
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import AppHeader from "../../components/AppHeader/AppHeader.vue";
import ScrollableItems from "../../components/ScrollableItems/ScrollableItems.vue";
import TableItem from "../../components/TableItem/TableItem.vue";
import AppFooter from "../../components/AppFooter/AppFooter.vue";
import { closeSearchInput, fetchTableData, getQueriedTables, handleAreaSelected, handleItemClicked, tableStore } from "@/store/tables";
import Pusher from "pusher-js";

onMounted(() => {


  const pusher = new Pusher("2cdbc34e236d4c107411", {
    cluster: "eu",
    encrypted: true,
  });

  const userData = ref(localStorage.getItem('userData'));
  let user = JSON.parse(userData.value);
  const channel = pusher.subscribe("sinerPOSPusher_" + user["code"]);
  channel.bind("table", (data) => {
    fetchTableData();
  });

  fetchTableData();

  tableStore.searchQuery = "";
});

const getTables = computed(() => {
  if (tableStore.searchQuery.length) {
    return tableStore.queryTables;
  } else {
    return tableStore.displayedTables;
  }
})

watch(() => tableStore.searchQuery, (value) => {
  getQueriedTables();
})





const items = [
  {
    icon: "fa fa-cutlery",
    text: "Tüm Masalar",
    type: "all-tables",
  },
  {
    icon: "fa-solid fa-champagne-glasses",
    text: "Boş Masalar",
    type: "open-tables",
  },
  {
    icon: "fa fa-search",
    text: "Masa Ara",
    type: "search-table",
  },
];
</script>
<style src="./TablesView.scss" lang="scss"></style>
