<template>
  <div v-if="props.show" class="c-modal">
    <div class="c-modal-content c-card">
      <div class="row mb-4">
        <div class="col-12">
          <div class="c-close" @click="close">&times;</div>
          <h3 class="mt-2 mb-4 center">Rezervasyon Yap</h3>
          <div class="form-group">
            <!-- <label for="name">Ad Soyad</label> -->
            <input v-model="reservation.name" type="text" class="form-control" id="name" placeholder="Ad Soyad" />
          </div>
          <div class="form-group">
            <!-- <label for="name">Telefon Numarasi</label> -->
            <input
              v-model="reservation.phone"
              type="number"
              class="form-control"
              id="name"
              placeholder="Telefon Numarasi"
            />
          </div>
          <div class="form-group">
            <button class="btn btn-primary btn-add" @click="saveReservation">Kaydet</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue';
import router from '../../../router';
import { setReservation } from '../../../store/table-detail';

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
});

const reservation = {
  name: '',
  phone: '',
};

const saveReservation = () => {
  setReservation(reservation.name + ' - ' + reservation.phone);
  close();
  router.push('/tables');
};

const emitClose = defineEmits(['close']);

const close = () => {
  emitClose('close');
};
</script>
<style src="./ReservationModal.scss" lang="scss"></style>
