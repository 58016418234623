<template>
  <div class="item-counter">
    <button @click="decrease">-</button>
    <div class="number">
      <span>{{ number }}</span>
    </div>
    <button @click="increase">+</button>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, watch } from "vue";

const props = defineProps({
  count: {
    type: Number,
    default: 0,
    required: false,
  },
  cartId: {
    type: Number,
    default: 0,
    required: false,
  },
});

const emits = defineEmits(["increase", "decrease", "change"]);

watch(() => props.count, (value) => {
  number.value = value;
})

onMounted(() => {
  if (props.count) {
    number.value = props.count;
  }
});

const number = ref(1);

const decrease = () => {
  if (number.value > 0) {
    number.value--;
    emits("decrease", props.cartId, number.value);
  }
};

const increase = () => {
  number.value++;
  emits("increase", props.cartId, number.value);
};

watch(() => number.value, (newVal) =>{
  emits("change", newVal)
})
</script>
<style src="./ItemCounter.scss" lang="scss"></style>
