import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../views/Login/UserLogin.vue';
import PinLogin from '../views/PinLogin/PinLogin.vue';
import TablesView from '../views/TablesView/TablesView.vue';
import MenuView from '../views/MenuView/MenuView.vue';
import auth from '../middleware/auth';
import log from '../middleware/log';

const routes = [
  {
    path: '/',
    name: 'UserLogin',
    component: UserLogin,
    meta: {
      title: 'Garson Girişi',
      middleware: [auth],
    },
  },
  {
    path: '/pin-login',
    name: 'PinLogin',
    component: PinLogin,
    meta: {
      title: 'Garson Girişi',
      middleware: [auth],
    },
  },

  {
    path: '/tables',
    name: 'TablesView',
    template: `<router-view />`,
    meta: {
      headerClass: 'header',
      sidebarClass: '',
      title: 'Masalar',
      middleware: [auth, log],
    },
    children: [
      {
        path: '',
        component: TablesView,
        meta: {
          auth: [1, 3],
        },
      },
      {
        path: ':id',
        template: `<router-view />`,
        children: [
          {
            path: '',
            component: MenuView,
            meta: {
              auth: [1, 3],
            },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  linkActiveClass: 'active',
  routes,
});

const default_title = 'Garson Yazılımı';
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || default_title;
  next();
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];

  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);

    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
